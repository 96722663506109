import * as React from 'react';

import logo from '../img/design/logo-type.svg';
import facebook from '../img/social/icons-facebook.svg';
import instagram from '../img/social/icons-instagram.svg';
import pinterest from '../img/social/icons-pinterest.png';

const year = new Date().getFullYear();

const Footer = () => (
    <footer className="footer section--orange has-text-white-ter">
        <div className="container p-4">
            <div className="columns is-centered p-6">
                <div className="column has-text-centered">
                    <img
                        src={logo}
                        alt="Sitteroo"
                        width="283px"
                        height="283px"
                    />
                </div>
                <div className="column is-flex is-flex-direction-column ">
                    <div className="container has-text-centered is-size-2">
                        <p className="sitteroo-h2">Let&#39;s be in touch</p>
                        <a
                            title="contact"
                            href="mailto:admin@sitteroo.com.au"
                            className="is-size-4 has-text-white is-primary block is-size-5-mobile"
                        >
                            @sitteroo.com.au
                        </a>
                    </div>
                    <div className="container has-text-centered mt-4">
                        <p className="sitteroo-monday-picnic">Follow us on</p>
                        <div className="sitteroo-social-links">
                            <a
                                title="facebook"
                                href="https://www.facebook.com/Sitteroo-111665234758375"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={facebook}
                                    alt="Facebook"
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        marginRight: '1em',
                                    }}
                                />
                            </a>
                            <a
                                title="instagram"
                                href="https://www.instagram.com/sitteroo_au/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={instagram}
                                    alt="Instagram"
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        marginLeft: '1em',
                                        marginRight: '1em',
                                    }}
                                />
                            </a>
                            <a
                                title="instagram"
                                href="https://www.pinterest.com/sitteroo/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={pinterest}
                                    alt="Pinterest"
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        marginLeft: '1em',
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-pastel has-text-centered p-4">
            <a
                className="sitteroo-footer-link"
                title="home"
                href="/"
            >{`@Sitteroo ${year}`}</a>

            <a
                className="sitteroo-footer-link"
                title="privacy policy"
                href="/about/privacy-policy/"
            >
                Privacy Policy
            </a>

            <a
                className="sitteroo-footer-link"
                title="terms of service"
                href="/about/terms-of-service/"
            >
                Terms of Service
            </a>
        </div>
    </footer>
);

export default Footer;
