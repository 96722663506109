import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import BlogRoll from '../components/BlogRoll';
import {metaDescription} from '../utils/format';

// eslint-disable-next-line
export const BlogPostTemplate = ({
    content,
    contentComponent,
    description,
    tags,
    title
}) => {
    const PostContent = contentComponent || Content;

    return (
        <div className="section section--salmon section--with-mask mask-four">
            <section className="container sitteroo-blog-post">
                <div className="content box">
                    <h1 className="sitteroo-blog-post-heading">{title}</h1>

                    {tags && tags.length ? (
                        <div className="mt-4 pl-0">
                            <ul className="taglist pink sitteroo-tags">
                                {tags.map((tag) => (
                                    <li key={`${tag}tag`}>
                                        <Link to={`/tags/${kebabCase(tag)}/`}>
                                            {tag}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    <p>{description}</p>
                    <PostContent content={content} />
                </div>
            </section>
            <section className="hero section--pastel section--with-mask mask mask-two">
                <div className="container">
                    <h2 className="sitteroo-monday-picnic yellow has-text-left pt-4">
                        More posts
                    </h2>
                    <div className="pb-6">
                        <BlogRoll />
                    </div>
                </div>
            </section>
        </div>
    );
};

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
};

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <Helmet titleTemplate="%s">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                    name="description"
                    content={`${metaDescription(post.frontmatter.description)}`}
                />
                <meta
                    name="og:description"
                    content={`${metaDescription(post.frontmatter.description)}`}
                />
            </Helmet>

            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
            />
        </Layout>
    );
};

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
};

export default BlogPost;

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
            }
        }
    }
`;
