import React from 'react';
import { Link } from 'gatsby';
import Logo from './Logo';
import window from '../utils/window';

const Navbar = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            drawerOpen: false,
            navBarActiveClass: '',
            isShop: window.location.href.match(/star-map|shop|products/gim) !== null,
            isBlog: window.location.href.match(/blog|tags/gim) !== null,
            isSubscribe: window.location.href.match(/contact\/subscribe/gim) !== null,
        };
    }

    toggleHamburger() {
        // toggle the active boolean in the state
        const active = !this.state.active;
        const navBarActiveClass = active ? 'is-active' : '';
        this.setState((previousState) => ({
            ...previousState,
            active,
            navBarActiveClass,
        }));
    }

    render() {
        return (
            <>
                <nav
                    className="navbar has-shadow is-transparent is-fixed-top"
                    role="navigation"
                    aria-label="main-navigation"
                >
                    <div className="container">
                        <div className="navbar-brand">
                            <Link
                                to="/"
                                className="navbar-item blog-logo"
                                title="Sitteroo"
                            >
                                <Logo
                                    isShop={this.state.isShop}
                                    isBlog={this.state.isBlog}
                                />
                            </Link>

                            {/* Hamburger menu */}
                            <div
                                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                                data-target="navMenu"
                                role="menuitem"
                                tabIndex={0}
                                onKeyPress={() => this.toggleHamburger()}
                                onClick={() => this.toggleHamburger()}
                            >
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>
                        <div
                            id="navMenu"
                            className={`navbar-menu ${this.state.navBarActiveClass}`}
                        >
                            <div className="navbar-end has-text-centered">
                                <Link className="navbar-item" to="/">
                                Home
                                </Link>
                                <Link className="navbar-item" to="/blog/">
                                Blog
                                </Link>
                                <Link className="navbar-item" to="/about/">
                                About
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
};

export default Navbar;
